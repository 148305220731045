

import { useState, useEffect, useMemo, Suspense } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { ToastContainer } from "react-toastify";
import { Box, LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';

import PrivateRoute from "PrivateRoute";
import { loadUser } from "redux/actions/auth.action";
import { loadingFalse } from "redux/actions/auth.action";
import getToken from "utils/getToken";
import MDProgress from "components/MDProgress";
import React from "react";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const disaptch = useDispatch()
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {

      if (route.private) {
        return <Route exact path={`${route.layout}${route.route}`} element={<PrivateRoute Component={route.component} />} key={route.key} />;
      } else {
        return <Route exact path={route.route} element={route.component} key={route.key} />
      }
    });
  const user = useSelector(state => state.auth.user);
  const { userLoading } = useSelector(state => state.auth);

  React.useEffect(async () => {
    const token = getToken();
    // eslint-disable-next-line
    if (token) {
      disaptch(loadUser());
    } else {
      disaptch(loadingFalse());
      // eslint-disable-next-line
    }
    // eslint-disable-next-line
  }, [])
  const queryClient = new QueryClient()
  return (
    <>


      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {userLoading ?
          <MainLoading /> :
          <QueryClientProvider client={queryClient}>
            {user && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="Renty"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />

              </>
            )}
            <Suspense fallback={<Box width='100%' overflow='hidden' ><LinearProgress sx={{ height: 3 }} color='info' /></Box>}>
              <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/sign-in" />} />
              </Routes>
            </Suspense>
            <ToastContainer
              style={{ fontSize: 12, zIndex: 999999 }}
              role='alert'
              limit={3}
              closeButton={true}
              autoClose={2000}
              position='top-right'
            />
          </QueryClientProvider>}
        <style>{`
              .MuiAutocomplete-input{
text-transform:capitalize !important
              }
          `}

        </style>
      </ThemeProvider>
    </>
  );
}


function MainLoading() {
  return (
    <Box height='100vh' width='100%' display='flex' alignItems='center' justifyContent='center' >
      <MDProgress circular />
    </Box>
  )
}
