import axios from 'axios';
import { BASE_URL } from 'source/constants';
import { toast } from 'react-toastify';

import getToken from 'utils/getToken';


export const USER_LOADED = 'USER_LOADED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const USER_LOADING = 'USER_LOADING';

export const CREATING_USER = 'CREATING_USER';
export const LOAD_CONFIG = 'LOAD_CONFIG';


export const signinUser = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/auth/signin`, {
      ...data
    });
    if (res.data) {
      toast.success(res.data.message);
      localStorage.setItem("access_token", `Bearer ${res?.data?.result?.token}`);
      dispatch(loadUser())
      return res.data.result
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};


export const loadUser = () => async (dispatch) => {
  const token = getToken();

  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/auth/check`);
    if (res.data) {
      dispatch({
        type: USER_LOADED,
        payload: res.data.result
      });
      return res.data
    }
  } catch (error) {
    dispatch({
      type: USER_LOADING
    });
    dispatch(logoutUser());

  }
};


export const loadUserWithoutLoading = () => async (dispatch) => {
  const token = getToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/auth/check`);
    if (res.data) {
      if (res.data.user.role === 'user') {
        dispatch({
          type: USER_LOADED,
          payload: res.data
        });
      }
    }
  } catch (error) {
  }
};


export const logoutUser = () => async (dispatch) => {

  localStorage.clear();
  dispatch({
    type: LOGOUT_USER
  })
};

export const loadingFalse = () => async (dispatch) => {
  dispatch({
    type: LOGOUT_USER,
    payload: null
  });
};


export const updateDealer = (data) => async () => {
  try {
    const res = await axios.put(`${BASE_URL}/dealers`, {
      ...data
    });
    if (res.data) {
      toast.success(res.data.message);
      return res.data.result
    }
  } catch (error) {
    toast.error('Something went wrong');
  }
};

