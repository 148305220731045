import React from "react";
import Icon from "@mui/material/Icon";
import Notfound from "pages/Notfound";

const Dashboard = React.lazy(() => import('pages/dashboard'));
const AddVehicle = React.lazy(() => import('pages/add-vehicle'));
const EditVehicles = React.lazy(() => import('pages/edit-vehicle'));

const Profile = React.lazy(() => import('pages/profile'));
const SignIn = React.lazy(() => import('pages//authentication/sign-in'));


// @mui icons

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    layout: 'dealer',
    private: true,
    component: Dashboard,
  },
  {
    type: "collapse",
    name: "Add Vehicle",
    key: "add-vehicle",
    icon: <Icon fontSize="small">directions_car</Icon>,
    route: "/edit-vehicle/:vehicleId",
    layout: 'dealer',
    private: true,
    noSidebar: true,
    component: EditVehicles,
  },
  {
    type: "collapse",
    name: "Add Vehicle",
    key: "add-vehicle",
    icon: <Icon fontSize="small">directions_car</Icon>,
    route: "/add-vehicle",
    layout: 'dealer',
    private: true,
    component: AddVehicle,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    layout: 'dealer',
    private: true,
    component: Profile,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    private: false,
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/not-found",
    component: <Notfound />,
  },
];

export default routes;
