import * as Actions from '../actions/auth.action';


const initialState = {
  isAuth: false,
  user: false,
  userLoading: true,
  createuserloading: false,
  config: null,
  showAds: false
};

function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Actions.USER_LOADED:
      return {
        ...state,
        user: payload,
        userLoading: false,
      };

    case Actions.USER_LOADING:
      return {
        ...state,
        userLoading: !state.userLoading
      };
    case Actions.LOGOUT_USER:
      return {
        ...state,
        user: null,
        userLoading: false
      };
    case Actions.CREATING_USER:
      return {
        ...state,
        createuserloading: !state.createuserloading
      };
    default:
      return state;
  }
}

export default auth;
