

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

//   components
import MDTypography from "components/MDTypography";

// Custom styles for MDProgress
import MDProgressRoot from "components/MDProgress/MDProgressRoot";


import CircularProgress from '@mui/material/CircularProgress';
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";



const MDProgress = forwardRef(({ circular, variant, color, value, label, size, loading, ...rest }, ref) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  function progressColor(darkMode, loading) {
    let color = "dark";
    if (darkMode || loading) {
      color = "light"
    } else if (!darkMode && loading) {
      color = "light"
    }
    else {
      color = "dark"
    }
    return color
  }
  return (<>
    {circular ? <MDBox display="flex" justifyContent="center" py={size === 20 ? 0 : 1.1} mx={size === 20 ? 1 : 0} >
      <CircularProgress color={progressColor(darkMode, loading)} size={size} />
    </MDBox> :
      <>
        {label && (
          <MDTypography variant="button" fontWeight="medium" color="text">
            {value}%
          </MDTypography>
        )}
        <MDProgressRoot
          {...rest}
          ref={ref}
          variant="determinate"
          value={value}
          ownerState={{ color, value, variant }}
        />
      </>
    }
  </>
  )
});

// Setting default values for the props of MDProgress
MDProgress.defaultProps = {
  variant: "contained",
  color: "info",
  value: 0,
  label: false,
};

// Typechecking props for the MDProgress
MDProgress.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  value: PropTypes.number,
  label: PropTypes.bool,
};

export default MDProgress;
