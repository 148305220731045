import { useSelector } from 'react-redux';
import { Navigate, useLocation } from "react-router-dom";

export default function PrivateRoute({ Component }) {
  const user = useSelector(state => state.auth.user);
  const location = useLocation();

  return (
    <>

      {!user && location.pathname !== '/authentication/reset-password' ? <Navigate to='/sign-in' /> :
        <Component />
      }
    </>
  )
}
