

//   base styles
import colors from "assets/theme/base/colors";



const { grey } = colors;

export default {
  styleOverrides: {
    select: {
      display: "grid",
      alignItems: "center",

      "&.Mui-selected": {
        backgroundColor: grey[800],
      },
      "&.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
        color: grey[800],
      },


    },
    selectMenu: {
      background: "none",
      height: "none",
      minHeight: "none",
      overflow: "unset",
    },

    icon: {
      display: "none",
    },
  },
};
